<template>
    <div class="applyInvoice">
        <div
            :style="{
                background:
                    'url(' +
                    require('@/assets/bg2.png') +
                    ') top left no-repeat #ffffff',
                backgroundSize: '100% ',
            }"
            class="top_box"
        >
            <p class="top_title">
                <span>
                    <i class="iconfont icon-feiyongshenqing"></i>
                    新增交付订单</span
                >
                <span>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                            margin-right: 20px;
                        "
                        >返回列表</el-button
                    ></span
                >
            </p>
            <div class="main_box">
                <div class="left_column">
                    <el-form label-position="left" size="small" ref="form">
                        <div class="column">
                            <el-form-item
                                label="订单编号"
                                label-width="75px"
                                required
                            >
                                <el-input
                                    v-model="form.orderPlanNo"
                                    style="width: 240px"
                                    placeholder="请输入交付订单编号"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="销售订单"
                                required
                                label-width="75px"
                            >
                                <el-select
                                    v-model="form.orderId"
                                    size="small"
                                    style="width: 240px"
                                    clearable
                                    placeholder="请选择或输入搜索"
                                    remote
                                    :remote-method="getData"
                                    @change="getOrderData"
                                    filterable
                                >
                                    <el-option
                                        v-for="item in orderDataList"
                                        :key="item.id"
                                        :label="item.orderNo"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="部门"
                                label-width="75px"
                                required
                            >
                                <el-cascader
                                    :disabled="isDisabled"
                                    size="small"
                                    ref="cascader"
                                    v-model="form.departmentId"
                                    :options="departmentList"
                                    style="width: 240px"
                                    :show-all-levels="false"
                                    placeholder="请选择部门"
                                    :props="{
                                        checkStrictly: true,
                                        expandTrigger: 'hover',
                                        emitPath: false,
                                        value: 'id',
                                        label: 'departmentName',
                                        children: 'children',
                                    }"
                                >
                                    <template slot-scope="{ node, data }">
                                        <div @click="cascaderClick(data)">
                                            <span>{{
                                                data.departmentName
                                            }}</span>
                                        </div>
                                    </template>
                                </el-cascader>
                            </el-form-item>
                            <el-form-item
                                label="客户"
                                label-width="75px"
                                required
                            >
                                <el-select
                                    :disabled="isDisabled"
                                    :style="{ width: '240px' }"
                                    v-model="form.customerId"
                                    size="small"
                                    filterable
                                    placeholder="请选择客户"
                                    :filter-method="getCustomerList"
                                    @change="changeCustomer"
                                >
                                    <el-option
                                        v-for="item in customerList"
                                        :key="item.id"
                                        :label="item.companyName"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="下单名称"
                                label-width="75px"
                                required
                            >
                                <el-input
                                    v-model="form.customerName"
                                    style="width: 240px"
                                    placeholder="请输入下单名称"
                                ></el-input
                            ></el-form-item>
                            
                            
                            <el-form-item
                                label="交付订单类型"
                                label-width="105px"
                                required
                            >
                                <el-select
                                    v-model="form.planType"
                                    size="small"
                                    style="width: 210px"
                                    clearable
                                    placeholder="请选择交付订单类型"
                                >
                                    <el-option
                                        v-for="item in planTypeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="应交付用户数"
                                label-width="120px"
                                required
                            >
                                <el-input
                                    v-model="form.shouldNumber"
                                    style="width: 195px"
                                    placeholder="请输入应交付用户数"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="实际交付用户数"
                                label-width="120px"
                                required
                            >
                                <el-input
                                    v-model="form.number"
                                    style="width: 195px"
                                    placeholder="请输入实际交付用户数"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="交付明细"
                                label-width="120px"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="form.detail"
                                    maxlength="1000"
                                    :rows="3" 
                                    style="width: 195px;height: 300px;"
                                    placeholder="请输入交付明细"
                                    clearable=""
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="column">
                            <el-form-item
                                label="订单时长"
                                label-width="75px"
                            >
                                <el-input
                                    :disabled="isDisabled"
                                    v-model="orderDuration"
                                    style="width: 240px"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="应交付年限"
                                label-width="105px"
                                required
                            >
                                <el-input
                                    v-model="form.shouldTerm"
                                    style="width: 210px"
                                    placeholder="请输入应交付年限"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="实际交付年限"
                                label-width="105px"
                                required
                            >
                                <el-input
                                    v-model="form.term"
                                    style="width: 210px"
                                    placeholder="请输入实际交付年限"
                                ></el-input
                            ></el-form-item>

                            <el-form-item
                                label="下单平台"
                                label-width="75px"
                            >
                                <el-select
                                    v-model="form.libraryCategory"
                                    size="small"
                                    style="width: 240px"
                                    clearable
                                    placeholder="请选择下单平台"
                                >
                                    <el-option
                                        v-for="item in accountListData"
                                        :key="item.id"
                                        :label="item.accountName"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="支付时间"
                                label-width="75px"
                                required
                            >
                                <el-date-picker
                                    v-model="form.payTime"
                                    style="width: 240px"
                                    type="date"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="请选择支付时间"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item
                                label="交付时间"
                                label-width="75px"
                                required
                            >
                                <el-date-picker
                                    v-model="form.placeOrderTime"
                                    style="width: 240px"
                                    type="date"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="请选择交付时间"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item
                                label="开通时间"
                                label-width="75px"
                                required
                            >
                                <el-date-picker
                                    v-model="form.openTime"
                                    type="date"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 240px"
                                    placeholder="请选择开通时间"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item
                                label="到期时间"
                                label-width="75px"
                                required
                            >
                                <el-date-picker
                                    v-model="form.expireTime"
                                    style="width: 240px"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="date"
                                    :picker-options="expireTimeOption"
                                    @change="
                                        (val) => {
                                            form.finalExpireTime = val;
                                        }
                                    "
                                    placeholder="请选择到期时间"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item
                                label="最终到期时间"
                                label-width="105px"
                                required
                            >
                                <el-date-picker
                                    v-model="form.finalExpireTime"
                                    style="width: 210px"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="date"
                                    :clearable="false"
                                    placeholder="请选择最终到期时间"
                                    :picker-options="expireTimeOption"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item
                                label="CorpID"
                                label-width="75px"
                                v-if="type == 1 && saleOrder.orderType == 1"
                            >
                                <el-input
                                    v-model="form.corpId"
                                    style="width: 240px"
                                    placeholder="请输入CorpID"
                                ></el-input
                            ></el-form-item>
                            <el-form-item
                                label="管理员账号"
                                label-width="105px"
                                v-if="type == 1 && saleOrder.orderType == 1"
                            >
                                <el-input
                                    v-model="form.adminAccount"
                                    style="width: 210px"
                                    placeholder="请输入管理员账号"
                                ></el-input
                            ></el-form-item>
                            <el-form-item
                                label="管理员账号"
                                label-width="105px"
                                v-if="type == 2 && saleOrder.orderType == 1"
                            >
                                <el-input
                                    v-model="form.adminAccount"
                                    style="width: 210px"
                                    placeholder="请输入管理员账号"
                                ></el-input
                            ></el-form-item>
                            <el-form-item
                                label="Boss额度"
                                label-width="75px"
                                required
                            >
                                <el-input
                                    v-model="form.amount"
                                    style="width: 240px"
                                    placeholder="请输入Boss额度"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="结算额度"
                                label-width="75px"
                                required
                            >
                                <el-input
                                    v-model="form.aliSettleAmount"
                                    style="width: 240px"
                                    placeholder="请输入结算额度"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="员工额度"
                                label-width="75px"
                                required
                            >
                                <el-input
                                    v-model="form.staffSettleAmount"
                                    style="width: 240px"
                                    placeholder="请输入员工额度"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="金额"
                                label-width="75px"
                                required
                                v-if="type == 12"
                            >
                                <el-input
                                    v-model="form.amount"
                                    style="width: 240px"
                                    placeholder="请输入金额"
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                label="员工"
                                label-width="75px"
                                required
                            >
                                <el-select
                                    :disabled="isDisabled"
                                    :style="{ width: '240px' }"
                                    v-model="form.adminId"
                                    size="small"
                                    filterable
                                    placeholder="请选择员工"
                                >
                                    <el-option
                                        v-for="item in userName"
                                        :label="item.nickName"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="bnt">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import {
    orderList,
    orderUpdate,
    orderGet,
    addOrderPlan,
} from '@/api/order/order.js';
import { accountList } from '@/api/report/report.js';
import { productAttributeRelationList } from '@/api/product/product.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import { roleList } from '@/api/user/clue';
import { customerList } from '@/api/customer/customer.js';
import { closedCusGet } from '@/api/closedCus/closedCus.js';
export default {
    components: {},
    data() {
        return {
            orderDuration: '',
            form: {
                departmentId: '',
                adminId: '',
                customerId: '',
                orderType: '',
                orderId: '',
                customerName: '',
                version: '',
                shouldNumber: '',
                shouldTerm: '',
                finalExpireTime: '',
                expireTime: '',
            },
            type: '',
            planTypeList: [],
            orderDataList: [],
            accountListData: [],
            productAttributeValueBOList: [],
            saleOrder: {},
            departmentList: [],
            userName: [],
            isDisabled: false,
            customerList: [],
        };
    },
    computed: {
        expireTimeOption() {
            let _this = this;
            return {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return (
                        date.getTime() <=
                        new Date(_this.form.openTime).getTime()
                    );
                },
            };
        },
    },
    created() {
        this.type = this.$route.query.type;
        this.planTypeList = this.$MailStatus.planTypeAliSel();
    },
    mounted() {
        if (this.$route.query.orderId) {
            console.log(this.$route.query);
            this.getOrderData(this.$route.query.orderId);
            this.getData(this.$route.query.orderNo);
        } else {
            this.getData();
        }

        this.accountList();
        this.changeType();
        this.getCustomerList();
        this.getDepartmentList();
    },
    methods: {
        getData(name) {
            let data = {
                param: {
                    statusList: [1, 2],
                    productCategory: this.type,
                },
            };
            if (name) {
                data.param.name = name;
            }

            orderList(data).then((res) => {
                if (res.code == 200) {
                    this.orderDataList = res.data.list;
                }
            });
        },
        accountList() {
            let data = {
                param: {
                    productType: this.type,
                },
                pageNum: 0,
                pageSize: 0,
            };
            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.accountListData = res.data.list;
                }
            });
        },
        changeType() {
            let data = {
                param: {
                    productId: this.type,
                },
            };
            productAttributeRelationList(data).then((res) => {
                if (res.code == 200) {
                    this.fieldList = res.data.list.sort(
                        this.compare('reorder')
                    );

                    this.productAttributeValueBOList = this.fieldList.find(
                        (item) => {
                            return item.attributeColumnName == 'productType';
                        }
                    ).productAttributeValueBOList;
                }
            });
        },
        compare(p) {
            return function (m, n) {
                var a = m[p];
                var b = n[p];
                return a - b; //升序
            };
        },
        getOrderData(val) {
            if (val) {
                let data = {
                    param: {
                        id: val,
                    },
                };
                orderGet(data).then((res) => {
                    if (res.code == 200) {
                        this.isDisabled = true;
                        this.saleOrder = res.data;
                        this.form.adminId = res.data.adminId;
                        this.form.departmentId = res.data.departmentId;
                        this.form.orderType = res.data.orderType;
                        this.form.customerId = res.data.customerId;
                        this.form.version = res.data.version;
                        this.form.customerName = res.data.companyName;
                        this.form.shouldNumber = res.data.shouldNumber;
                        this.form.shouldTerm = res.data.shouldTerm;
                        this.form.orderId = res.data.id;
                        var date = new Date(res.data.createTime);
                        var Y = (date.getFullYear() + res.data.orderTerm) + '-';
                        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                        var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                        this.orderDuration = res.data.createTime.slice(0, 10) + '至' + Y + M + D;
                        this.getCustomerList(res.data.companyName);
                        this.getDepartmentList();
                        this.getUserList(res.data.departmentId);
                    }
                });
            } else {
                this.saleOrder = {};
                this.form.adminId = '';
                this.form.departmentId = '';
                this.form.orderType = '';
                this.form.customerId = '';
                this.form.customerName = '';
                this.form.shouldNumber = '';
                this.form.shouldTerm = '';
                this.form.version = '';
                this.orderDuration = '';
                this.isDisabled = false;
                this.getCustomerList();
                this.getDepartmentList();
            }
        },

        cascaderClick(nodeData) {
            this.form.adminId = '';
            this.form.departmentId = nodeData.id;
            this.getUserList(nodeData.id);
            this.$refs.cascader.checkedValue = nodeData.departmentName;
            this.$refs.cascader.computePresentText();
            this.$refs.cascader.toggleDropDownVisible(false);
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = this.$Tree.treeDataTranslate(
                        res.data
                    );
                }
            });
        },
        // 获取部门成员
        getUserList(departmentId) {
            let data = {
                param: {
                    departmentId,
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        getCustomerList(name) {
            let data = {
                param: {},
            };
            if (name) {
                data.param.name = name;
            }
            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerList = res.data.list;
                }
            });
        },
        changeCustomer(val) {
            let obj = this.customerList.find((item) => item.id == val);
            this.form.customerName = obj.companyName;
        },
        onClose() {
            window.history.go(-1);
        },
        onAddPlan(that){
            let data = {
                param: {
                    ...that.form,
                    productCategory: that.type,
                },
            };
            addOrderPlan(data).then((res) => {
                if (res.code == 200) {
                    that.$message.success('交付成功');
                    for (const i in that.form) {
                        that.form[i] = '';
                    }
                    that.onClose();
                }
            });
        },
        onSubmit() {
            if (!this.form.orderPlanNo) {
                return this.$message.error('请输入交付订单编号');
            }
            if (!this.form.orderId) {
                return this.$message.error('请选择销售订单');
            }
            if (!this.form.openTime) {
                return this.$message.error('请选择开通时间');
            }
            if (!this.form.departmentId) {
                return this.$message.error('请选择部门');
            }
            if (!this.form.customerId) {
                return this.$message.error('请选择客户');
            }
            if (!this.form.amount) {
                return this.$message.error('请输入Boss额度');
            }
            if (!this.form.amount) {
                return this.$message.error('请输入金额');
            }
            if (!this.form.aliSettleAmount) {
                return this.$message.error('请输入结算额度');
            }
            if (!this.form.staffSettleAmount) {
                return this.$message.error('请输入员工额度');
            }
           
            if (!this.form.placeOrderTime) {
                return this.$message.error('请选择交付时间');
            }
            if (!this.form.payTime) {
                return this.$message.error('请选择支付时间');
            }
            if (!this.form.expireTime) {
                return this.$message.error('请选择到期时间');
            }
            if (!this.form.adminId) {
                return this.$message.error('请选择员工');
            }
            if (!this.form.customerName) {
                return this.$message.error('请输入下单名称');
            }
            
            if (!this.form.planType) {
                return this.$message.error('请选择交付订单类型');
            }
            this.onAddPlan(this);
           
        },
    },
};
</script>
<style lang="scss" scoped>
.applyInvoice {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top_box {
        padding: 16px 0 0;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .main_box {
        flex: 1;
        padding-top: 27px;
        display: flex;
        .left_column {
            flex: 2;
            padding-left: 53px;
            .el-form {
                display: flex;
            }
            .column {
                flex: 1;
            }
        }
        .right_column {
            flex: 1;
            > div {
                width: 360px;
                min-height: 370px;
                background: #ffffff;
                border-radius: 10px;
                border: 1px dashed #2370eb;
                box-sizing: border-box;

                padding-top: 15px;
                > p {
                    position: relative;
                    height: 27px;
                    line-height: 23px;
                    color: #fff;
                    margin-bottom: 20px;
                    span {
                        position: relative;
                        z-index: 1;
                        font-size: 14px;
                        padding-left: 15px;
                    }
                }
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;
                }
                > div {
                    font-size: 14px;
                    color: #333;
                    padding-left: 44px;
                    line-height: 2.5;
                    em {
                        font-style: normal;
                        color: #666;
                        display: inline-block;
                        width: 80px;
                    }
                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .top_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icon-feiyongshenqing {
            color: #2370eb;
            margin-left: 19px;
            margin-right: 13px;
            font-weight: 600;
        }
    }
    .text_box {
        display: flex;
        padding-left: 52px;
        align-items: center;
        p {
            font-size: 14px;
            span:nth-child(1) {
                color: #666666;
                margin-right: 12px;
            }
            span:nth-child(2) {
                color: #333333;
                margin-right: 75px;
            }
        }
    }
    .invoice_box {
        margin-top: 12px;
        border-radius: 1px;
        flex: 1;
        background: #fff;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1;
    }
    .bnt {
        background: #fff;
        padding: 14px 0;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        button {
            padding: 5px 30px;
        }
    }
}
</style>
